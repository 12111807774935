import React from "react";
import { FormButton } from "../../../components/Button";
import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../../../store";
import {
  removeMultiSelectAnswere,
  setCurrentQuestionAnswere,
  setMultiSelectAnswere,
} from "../../../store/Question/QuestionsActions";
import { multiSelectFormStyles } from "../../../../styles/multiSelectForm";
interface Props {
  onNextPress: () => void;
  onBackPress: () => void;
  onFinishPress?: () => void;
  selectedAnswere?: string;
  isFinished?: boolean;
}

export const MultiSelectForm = ({
  onNextPress = () => null,
  onBackPress = () => null,
  onFinishPress = () => null,
  isFinished = false,
}: Props) => {
  const classes = multiSelectFormStyles();
  const { questions } = useSelector(
    (state: ApplicationState) => state.QuestionReducer
  );
  const dispatch = useDispatch();

  const getState = () => {
    let _answeres: AnsweresState[];
    let _question: string;
    let isAnswereSelected = false;
    let _multiSelectAnswere: MultiSelectAnsweresState[];
    let qNo;
    if (questions && questions[questions.length - 1]) {
      const { answeres, question, questionNumber, multiSelectAnswere } =
        questions[questions.length - 1];
      _answeres = answeres;
      _question = question;
      isAnswereSelected = questions[questions.length - 1].multiSelectAnswere
        ?.length
        ? true
        : false;
      qNo = questionNumber;
      _multiSelectAnswere = multiSelectAnswere ?? [];
    } else {
      _answeres = [];
      _question = "";
      isAnswereSelected = false;
      _multiSelectAnswere = [];
      qNo = 1;
    }

    return {
      _answeres,
      _question,
      isAnswereSelected,
      qNo,
      _multiSelectAnswere,
    };
  };
  const handleChange = ({ id = "", answere = "", subAnswere = "" }) => {
    console.log({
      id,
      index: questions.length - 1,
      question: getState()._question,
      answere: answere,
      subAnswere,
      selectedAnswere: answere,
    });

    dispatch(
      setMultiSelectAnswere({
        id,
        index: questions.length - 1,
        question: "",
        answere: answere,
        subAnswere,
        selectedAnswere: answere,
      })
    );
  };

  const getFormState = (id = "") => {
    let isChecked = false;
    let inputValue = "";
    const multiAnasweres =
      questions &&
      questions[questions.length - 1] &&
      questions[questions.length - 1].multiSelectAnswere
        ? questions[questions.length - 1].multiSelectAnswere
        : [];
    if (multiAnasweres && multiAnasweres.length >= 1) {
      const _data = multiAnasweres.find((item) => item.id === id);
      if (_data && _data.id) {
        isChecked = true;
        inputValue = _data && _data.subAnswere ? _data.subAnswere : "";
      } else {
        isChecked = false;
        inputValue = "";
      }
    }
    return { isChecked, inputValue };
  };

  const handleCheckBoxChange = (
    e: React.SyntheticEvent,
    checked: boolean,
    ans: AnsweresState
  ) => {
    if (checked) {
      handleChange(ans);
    } else {
      dispatch(
        removeMultiSelectAnswere({ id: ans.id, index: questions.length - 1 })
      );
    }
  };

  return (
    <>
      <div className={classes.questionAnswereContainer}>
        <div className={classes.questionContainer}>
          <div className={classes.questionInnerContainer}>
            <Typography
              className={classes.questionNumberText}
              fontSize={"20px"}
              fontWeight={"bold"}
              fontFamily={"Poppins"}
              style={{ marginBottom: "20px" }}
            >
              {getState()._question}
            </Typography>
          </div>
          {getState()._answeres.map((ans, index) => {
            return (
              <div className={classes.answereContainer}>
                <FormControlLabel
                  label={
                    <Typography
                      fontSize={"16px"}
                      fontWeight={"regular"}
                      fontFamily={"Poppins"}
                    >
                      {ans.answere}
                    </Typography>
                  }
                  onChange={(e, checked) => {
                    handleCheckBoxChange(e, checked, ans);
                  }}
                  control={
                    <Checkbox checked={getFormState(ans.id).isChecked} />
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <FormButton
          btnText={"Back"}
          onClick={onBackPress}
          btnStyle={{
            backgroundColor: "#FFFFFF",
            color: "black",
            padding: "10px 60px",
            elevation: 0,
            border: "1.5px solid #000000",
            marginTop: "50px",
          }}
        />

        {isFinished ? (
          <FormButton
            btnText={"Finish"}
            onClick={() => {
              if (getState().isAnswereSelected) {
                onFinishPress();
              }
            }}
            btnStyle={{
              backgroundColor: "#5E48F1",
              marginLeft: "30px",
              color: "#FFFFFF",
              padding: "0 60px",
              elevation: 0,
              marginTop: "50px",
            }}
          />
        ) : (
          <FormButton
            btnText={"Next"}
            disabled={!getState().isAnswereSelected}
            onClick={() => {
              if (getState().isAnswereSelected) {
                onNextPress();
              }
            }}
            btnStyle={{
              backgroundColor: "#5E48F1",
              marginLeft: "30px",
              color: "#FFFFFF",
              padding: "0 60px",
              elevation: 0,
              marginTop: "50px",
            }}
          />
        )}
      </div>
    </>
  );
};
