import React, { useState } from "react";
import { HireForm } from "../../components/HireForm";
import {
  goToPrevQuestion,
  setQuestion,
} from "../../store/Question/QuestionsActions";
import { useDispatch } from "react-redux";
import { question9 } from "./data";

export const Question8 = ({ onNextPress = (id: string) => {}, ...rest }) => {
  const dispatch = useDispatch();
  return (
    <HireForm
      onNextPress={() => {
        dispatch(setQuestion(question9));
      }}
      onBackPress={() => {
        dispatch(goToPrevQuestion());
      }}
      {...rest}
    />
  );
};
