import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { InputDropDown } from "../../../components/InputDropdown";
import Chip from "@mui/material/Chip";
import { FormButton } from "../../../components/Button";
import {
  goToPrevQuestion,
  removeSelectorAnswere,
  setQuestion,
  setSelectorQuestionAnswere,
} from "../../../store/Question/QuestionsActions";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import { question7 } from "../data";
import { useSelectorFormStyles } from "../../../../styles/selectorForm";

export const SelectorForm = () => {
  const { questions } = useSelector(
    (state: ApplicationState) => state.QuestionReducer
  );

  const classes = useSelectorFormStyles();
  const dispatch = useDispatch();
  const getOptions = () => {
    if (
      questions &&
      questions[questions.length - 1] &&
      questions[questions.length - 1].answeres
    ) {
      const _answeresList = questions[questions.length - 1].answeres;
      let filteredList = [];
      for (let i = 0; i < _answeresList.length; i++) {
        if (i >= 20) {
          break;
        }
        filteredList.push(_answeresList[i]);
      }

      return filteredList;
    } else {
      return [];
    }
  };
  const getOptionsList = () => {
    if (
      questions &&
      questions[questions.length - 1] &&
      questions[questions.length - 1].answeres
    ) {
      return questions[questions.length - 1].answeres;
    } else {
      return [];
    }
  };
  const getOptionsAnswere = () => {
    if (
      questions &&
      questions[questions.length - 1] &&
      questions[questions.length - 1].selectorAnsweres
    ) {
      return questions[questions.length - 1].selectorAnsweres;
    } else {
      return [];
    }
  };
  const isError = () => {
    const _selectedAnswere = getOptionsAnswere() ?? [];
    if (_selectedAnswere && _selectedAnswere.length >= 1) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div className={classes.questionAndDropDownContainer}>
        <div className={classes.questionContainer}>
          <Typography
            className={classes.questionNumber}
            fontSize={"20px"}
            fontWeight={"bold"}
            fontFamily={"Poppins"}
          >
            What skills would you like to see in your new hire?
          </Typography>
        </div>

        <InputDropDown options={getOptionsList()} />
        {getOptionsAnswere()?.map((item, index) => {
          return (
            <Chip
              deleteIcon={<CloseIcon style={{ color: "#000000" }} />}
              style={{
                width: "max-content",
                marginTop: "20px",
                marginRight: "10px",
                backgroundColor: "rgba(94, 72, 241, 0.38)",
                borderRadius: "10px",
              }}
              label={
                <Typography
                  className={classes.questionNumber}
                  fontSize={"12px"}
                  fontWeight={"500"}
                  fontFamily={"Poppins"}
                >
                  {item.label}
                </Typography>
              }
              onDelete={() => {
                dispatch(removeSelectorAnswere(item));
              }}
            />
          );
        })}
      </div>
      <div>
        <Typography
          style={{ fontWeight: "normal", marginTop: "50px" }}
          fontSize={"20px"}
          fontWeight={"regular"}
          fontFamily={"Poppins"}
        >
          Popular Skill for Software Developer
        </Typography>
        {getOptions().map((item, index) => (
          <Chip
            style={{
              width: "max-content",
              marginRight: "20px",
              marginTop: "10px",
              backgroundColor: "rgba(94, 72, 241, 0.12)",
              borderRadius: "10px",
            }}
            clickable={true}
            onClick={() => {
              dispatch(
                setSelectorQuestionAnswere({
                  index: questions.length - 1,
                  answere: item,
                  question:
                    "What skills would you like to see in your new hire?",
                })
              );
            }}
            icon={<AddIcon />}
            label={
              <Typography
                className={classes.questionNumber}
                fontSize={"12px"}
                fontWeight={"500"}
                fontFamily={"Poppins"}
              >
                {item.label}
              </Typography>
            }
          />
        ))}
      </div>
      <div className={classes.buttonContainer}>
        <FormButton
          btnText={"Back"}
          onClick={() => {
            dispatch(goToPrevQuestion());
          }}
          btnStyle={{
            backgroundColor: "#FFFFFF",
            color: "black",
            padding: "10px 60px",
            elevation: 0,
            border: "1.5px solid #000000",
          }}
        />

        <FormButton
          disabled={isError()}
          btnText={"Next"}
          onClick={() => {
            const _selectedAnswere = getOptionsAnswere() ?? [];
            if (_selectedAnswere && _selectedAnswere.length >= 1) {
              dispatch(setQuestion(question7));
            }
          }}
          btnStyle={{
            backgroundColor: "#5E48F1",
            marginLeft: "30px",
            color: "#FFFFFF",
            padding: "0 60px",
            elevation: 0,
          }}
        />
      </div>
    </>
  );
};
