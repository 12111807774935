import { QuestionActionTypes } from "../redux/actiontypes";

const INITIAL_STATE: QuestionState = {
  questions: [],
  questionNumber: 0,
  formAnsweres: [],
  loading: false,
  alertMessage: "",
};

const QuestionReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case QuestionActionTypes.SET_CURRENT_QUESTION: {
      const _state = { ...state };
      const { questionList = [], questionNumber = 0 } = action.payload;
      _state.questions.push(questionList);
      _state.questionNumber = questionNumber;
      return _state;
    }
    case QuestionActionTypes.SET_CURRENT_QUESTION_ANSWERE: {
      const _state = { ...state };
      const {
        index,
        answere,
        selectedAnswere,
        question,
        subAnswere = [],
      } = action.payload;

      if (_state.questions[index]) {
        let _selectorAnsweres = [...(_state.formAnsweres ?? [])];
        _selectorAnsweres[index] = {
          question,
          answere: selectedAnswere,
          selectorAnsweres: subAnswere,
        };

        _state.formAnsweres = _selectorAnsweres;
        _state.questions[index] = {
          ..._state.questions[index],
          answere,
          subSelectedAnswere: subAnswere,
        };
      }
      return _state;
    }
    case QuestionActionTypes.SET_SELECTOR_ANSWERE: {
      const _state = { ...state };
      const { index, answere, question } = action.payload;

      if (_state.questions[index]) {
        let _selectorAnsweres = [
          ...(_state.questions[index].selectorAnsweres ?? []),
        ];
        _selectorAnsweres = [..._selectorAnsweres, answere];
        _state.questions[index].selectorAnsweres = _selectorAnsweres;
        if (answere && answere.id) {
          const answereList = _state.questions[index].answeres;
          const _filteredAnswere = answereList.filter(
            (item) => item.id !== answere.id
          );
          _state.questions[index].answeres = _filteredAnswere;
          let _formAnswweres = [...(_state.formAnsweres ?? [])];
          _formAnswweres[index] = {
            question,
            selectorAnsweres: _selectorAnsweres,
          };

          _state.formAnsweres = _formAnswweres;
        }
      }
      return _state;
    }
    case QuestionActionTypes.SET_PREV_QUESTION_ANSWERE: {
      const _state = { ...state };
      _state.questions.pop();
      if (_state.questionNumber > 0) {
        _state.questionNumber = _state.questionNumber - 1;
      }
      return _state;
    }
    case QuestionActionTypes.REMOVE_SELECTOR_ANSWERE: {
      const _state = { ...state };
      const { id } = action.payload;
      const _data = _state.questions[_state.questions.length - 1];
      if (_data.selectorAnsweres && _data.selectorAnsweres.length >= 0) {
        const _filteredSelector = _data.selectorAnsweres.filter(
          (item) => item.id !== id
        );
        _state.questions[_state.questions.length - 1].selectorAnsweres =
          _filteredSelector;
        _state.questions[_state.questions.length - 1].answeres.push(
          action.payload
        );

        if (
          _state.formAnsweres &&
          _state.formAnsweres &&
          _state.formAnsweres[_state.formAnsweres.length - 1] &&
          _state.formAnsweres[_state.formAnsweres.length - 1].selectorAnsweres
        ) {
          const _filteredFormAnsweres = _state.formAnsweres[
            _state.formAnsweres.length - 1
          ].selectorAnsweres?.filter((item) => item.id !== id);
          _state.formAnsweres[_state.formAnsweres.length - 1].selectorAnsweres =
            _filteredFormAnsweres;
        }
      }

      return _state;
    }

    case QuestionActionTypes.SET_MULTI_SELECT_ANSWERE: {
      const _state = { ...state };
      const { id, question, answere, subAnswere, index } = action.payload;

      const _questionIndex = _state.questions.length - 1;
      if (_state.questions && _state.questions[_questionIndex]) {
        const payload = { id, question, answere, subAnswere };
        const _answeres =
          _state.questions[_questionIndex].multiSelectAnswere ?? [];
        const foundIndex = _answeres.findIndex((el) => el.id === id);

        if (foundIndex < 0) {
          _answeres.push(payload);
        } else {
          _answeres[foundIndex] = payload;
        }
        _state.questions[_questionIndex].multiSelectAnswere = _answeres;

        let _formAnswweres = [...(_state.formAnsweres ?? [])];
        _formAnswweres[index] = {
          multiSelectAnswere: _answeres,
          question: _state.questions[_questionIndex].question,
        };
        _state.formAnsweres = _formAnswweres;
      }
      return _state;
    }
    case QuestionActionTypes.REMOVE_MULTI_SELECT_ANSWERE: {
      const _state = { ...state };
      const { id, index } = action.payload;
      const _questionIndex = _state.questions.length - 1;
      if (_state.questions && _state.questions[_questionIndex]) {
        const _answeres =
          _state.questions[_questionIndex].multiSelectAnswere ?? [];
        const filteredData = _answeres.filter((el) => el.id !== id);
        _state.questions[_questionIndex].multiSelectAnswere = filteredData;

        let _formAnswweres = [...(_state.formAnsweres ?? [])];
        _formAnswweres[index] = {
          multiSelectAnswere: filteredData,
          question: _state.questions[_questionIndex].question,
        };
        _state.formAnsweres = _formAnswweres;
      }
      return _state;
    }

    case QuestionActionTypes.STORE_ANSWERE_START:
      return {
        ...state,
        loading: true,
        alertMessage: "",
      };
    case QuestionActionTypes.STORE_ANSWERE_SUCESS:
      return {
        ...state,
        loading: false,
        alertMessage: "Submitted Successfully",
      };
    case QuestionActionTypes.STORE_ANSWERE_FAILED:
      return {
        ...state,
        loading: false,
        alertMessage: "Something went wrong",
      };
    case QuestionActionTypes.RESET_ANSWERE_STORE_STATE:
      return {
        ...state,
        loading: false,
        alertMessage: "",
      };

    default: {
      return state;
    }
  }
};

export default QuestionReducer;
