import React from "react";
import { useQuestionRendererStyles } from "../../../../styles/questionRenderer";
export const ImageGrid = ({ img1 = "", img2 = "" ,img3=""}) => {
  const classes = useQuestionRendererStyles();

  return (
    <div className={classes.imageGridContainer}>
      <div className={classes.imageContainer}>
        <img src={img1} alt="logo" className={classes.image} />
        <img src={img2} alt="logo" className={classes.image} />
        <img src={img3} alt="logo" className={classes.image} />
      </div>
    </div>
  );
};
