import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { randomIntFromInterval } from "../helpers/common";
import { question6 } from "../main/Question5/data";
import { ApplicationState } from "../store";
import { setSelectorQuestionAnswere } from "../store/Question/QuestionsActions";

interface Props {
  options: AnsweresState[];
}

export const InputDropDown = ({ options }: Props) => {
  const dispatch = useDispatch();
  const { questions, formAnsweres } = useSelector(
    (state: ApplicationState) => state.QuestionReducer
  );
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  if (options.length <= 0) return null;

  const getState = () => {
    let _answeres: SelectorAnsweresState[];
    let _question: string;
    let isAnswereSelected = "";
    let qNo;
    if (questions && questions[questions.length - 1]) {
      const { selectorAnsweres, question, questionNumber } =
        questions[questions.length - 1];
      _answeres = selectorAnsweres ?? [];
      _question = question;
      isAnswereSelected = questions[questions.length - 1].answere ?? "";
      qNo = questionNumber;
    } else {
      _answeres = [];
      _question = "";
      isAnswereSelected = "";
      qNo = 1;
    }

    return { _answeres, _question, isAnswereSelected, qNo };
  };

  const handleInputChange = (e: any, value: any, reason: any) => {
    const val = e && e.target && e.target.value ? e.target.value : "";
    setInputValue(val);
    // only open when inputValue is not empty after the user typed something
    if (!val) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <Autocomplete
      disablePortal
      freeSolo
      open={open}
      onOpen={() => {
        // only open when in focus and inputValue is not empty
        if (inputValue) {
          setOpen(true);
        }
      }}
      onClose={() => setOpen(false)}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={(event, value) => {
        setInputValue("");
        if (typeof value === "string") {
          const min = question6.questionList.answeres.length;
          const _id = randomIntFromInterval(min);

          dispatch(
            setSelectorQuestionAnswere({
              index: questions.length - 1,
              answere: {
                id: `${_id}`,
                label: value,
                answereNumber: `${_id}`,
              },
              question: getState()._question,
            })
          );
        } else if (typeof value === "object" && value) {
          dispatch(
            setSelectorQuestionAnswere({
              index: questions.length - 1,
              answere: value,
              question: getState()._question,
            })
          );
        }
      }}
      options={options}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};
