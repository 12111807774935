import { LinearProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import img from "../../../assets/clients/worldmap.png";
import { useDispatch } from "react-redux";
import { successData } from "./data";
import { setQuestion } from "../../store/Question/QuestionsActions";

export const TalentSearching = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(setQuestion(successData));
    }, 4000);
  }, [dispatch]);

  return (
    <div>
      <LinearProgress style={{ marginTop: "50px" }} />
      <img
        alt="logo"
        style={{
          display: "block",
          width: "100%",
          alignSelf: "center",
          margin: "50px 0",
        }}
        src={img}
      />
      <Typography
        style={{ textAlign: "center" }}
        fontSize={"20px"}
        fontWeight={"regular"}
        fontFamily={"Poppins"}
      >
        Looking for engineers based on your requirements...
      </Typography>
    </div>
  );
};
