import React, { useEffect, useState } from "react";
import { HireForm } from "../../components/HireForm";
import { useDispatch, useSelector } from "react-redux";
import { setQuestion } from "../../store/Question/QuestionsActions";
import { ApplicationState } from "../../store";
import { currentQuestion, nextQuestion } from "./data";

export const Question1 = ({ ...rest }) => {
  const dispatch = useDispatch();
  const { questions } = useSelector(
    (state: ApplicationState) => state.QuestionReducer
  );

  useEffect(() => {
    if (questions.length <= 0) {
      dispatch(setQuestion(currentQuestion));
    }
  }, [questions, dispatch]);

  return (
    <HireForm
      onNextPress={() => {
        dispatch(setQuestion(nextQuestion));
      }}
      onBackPress={() => {}}
      {...rest}
    />
  );
};
