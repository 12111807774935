import { makeStyles } from "@mui/styles";

export const useConnectClientStyles = makeStyles(() => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "600px",
      justifyContent: "space-evenly",
      width: "80%",
      margin: "auto",
    },
    title: {
      fontWeight: "normal",
    },
    divider: { margin: "20px 0" },
    connectButton: {
      backgroundColor: "#2ACB8B",
      width: "30%",
      alignSelf: "center",
      padding: "15px",
    },
  };
});
