// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyChTybuii3_VEpVUck2YuQmFLJsE231VaU",
  authDomain: "koding-talent.firebaseapp.com",
  projectId: "koding-talent",
  storageBucket: "koding-talent.appspot.com",
  messagingSenderId: "166745470500",
  appId: "1:166745470500:web:4b9f69c129a919825dcab1",
  measurementId: "G-1GSRLS9RM6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
