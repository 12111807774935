export const question9 = {
  questionList: {
    questionNumber: 8,
    question: "When would you need the new hire to start?",
    answeres: [
      { id: "8a", answere: "Immediately", answereNumber: "a" },
      {
        id: "8b",
        answere: "In 1 to 2 weeks",
        answereNumber: "b",
      },
      {
        id: "8c",
        answere: "More than 2 weeks from now",
        answereNumber: "c",
      },
    ],
  },
  questionNumber: 7,
};
