import React from "react";
import { HireForm } from "../../components/HireForm";
import {
  goToPrevQuestion,
  setQuestion,
} from "../../store/Question/QuestionsActions";
import { useDispatch } from "react-redux";
import { talentSearch } from "./data";

export const Question10 = ({ onNextPress = (id: string) => {}, ...rest }) => {
  const dispatch = useDispatch();
  return (
    <HireForm
      isFinished={true}
      onFinishPress={() => {
        dispatch(setQuestion(talentSearch));
      }}
      onNextPress={() => {}}
      onBackPress={() => {
        dispatch(goToPrevQuestion());
      }}
      {...rest}
    />
  );
};
