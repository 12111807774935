import { makeStyles } from "@mui/styles";

export const useHeaderStyles = makeStyles(() => {
  return {
    container: {
      display: "flex",
    },
    logo: {
      height: "42.98px",
      cursor: "pointer",

      marginTop: 20,
    },
  };
});
