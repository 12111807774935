export const question5 = {
  questionList: {
    questionNumber: 4,
    question: "What kind of Developer are you looking for",
    answeres: [
      { id: "4a", answere: "Front-End", answereNumber: "a" },
      {
        id: "4b",
        answere: "Back-End",
        answereNumber: "b",
      },
      {
        id: "4c",
        answere: "Full-Stack",
        answereNumber: "c",
      },
    ],
  },
  questionNumber: 3,
};
