import React from "react";
import { FormButton } from "./Button";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "../store";
import { setCurrentQuestionAnswere } from "../store/Question/QuestionsActions";
import { useHireFormStyles } from "../../styles/hireform";
interface Props {
  onNextPress: () => void;
  onBackPress: () => void;
  onFinishPress?: () => void;
  selectedAnswere?: string;
  isFinished?: boolean;
}

export const HireForm = ({
  onNextPress = () => null,
  onBackPress = () => null,
  onFinishPress = () => null,
  isFinished = false,
}: Props) => {
  const classes = useHireFormStyles();
  const { questions } = useSelector(
    (state: ApplicationState) => state.QuestionReducer
  );
  const dispatch = useDispatch();

  const getState = () => {
    let _answeres: AnsweresState[];
    let _question: string;
    let isAnswereSelected = "";
    let isSubAnswereSelected: string;
    let qNo;
    if (questions && questions[questions.length - 1]) {
      const { answeres, question, questionNumber, subSelectedAnswere } =
        questions[questions.length - 1];
      _answeres = answeres;
      _question = question;
      isAnswereSelected = questions[questions.length - 1].answere ?? "";
      qNo = questionNumber;
      isSubAnswereSelected =
        subSelectedAnswere && subSelectedAnswere.id
          ? subSelectedAnswere.id
          : "";
    } else {
      _answeres = [];
      _question = "";
      isAnswereSelected = "";
      isSubAnswereSelected = "";
      qNo = 1;
    }

    return {
      _answeres,
      _question,
      isAnswereSelected,
      qNo,
      isSubAnswereSelected,
    };
  };
  const handleChange = ({ id = "", answere = "", question = "" }) => {
    dispatch(
      setCurrentQuestionAnswere({
        index: questions.length - 1,
        answere: id,
        selectedAnswere: answere,
        question,
      })
    );
  };
  const handleSubAnswereChange = ({
    answere = "",
    question = "",
    subAnswere = {},
  }) => {
    dispatch(
      setCurrentQuestionAnswere({
        index: questions.length - 1,
        answere: getState().isAnswereSelected,
        selectedAnswere: answere,
        question,
        subAnswere,
      })
    );
  };

  return (
    <>
      <div className={classes.questionAnswereContainer}>
        <div className={classes.questionContainer}>
          <div className={classes.questionInnerContainer}>
            <Typography
              className={classes.questionNumberText}
              fontSize={"20px"}
              fontWeight={"bold"}
              fontFamily={"Poppins"}
            >
              {getState()._question}
            </Typography>
          </div>

          {getState()._answeres.map((ans, index) => {
            const _subQuestions =
              ans &&
              ans.subQuestion &&
              ans.subQuestion &&
              ans.subQuestion.answeres &&
              ans.subQuestion.answeres.length
                ? ans.subQuestion.answeres
                : [];

            return (
              <div>
                <div className={classes.answereContainer}>
                  <RadioGroup
                    onChange={() =>
                      handleChange({ ...ans, question: getState()._question })
                    }
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      checked={getState().isAnswereSelected === ans.id}
                      control={<Radio />}
                      label={
                        <Typography
                          fontSize={"20px"}
                          fontWeight={"regular"}
                          fontFamily={"Poppins"}
                        >
                          {ans.answere}
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </div>
                <div style={{ marginLeft: "70px" }}>
                  {getState().isAnswereSelected === ans.id &&
                  _subQuestions.length ? (
                    <>
                      <Typography
                        fontSize={"16px"}
                        fontWeight={"regular"}
                        fontFamily={"Poppins"}
                      >
                        {ans.subQuestion?.question}
                      </Typography>
                      {_subQuestions.map((subItem) => {
                        return (
                          <RadioGroup
                            style={{ marginLeft: "30px" }}
                            onChange={() => {
                              const _subAnswere = {
                                id: subItem.id,
                                label: subItem.answere,
                                answere: subItem.answere,
                                answereNumber: subItem.answereNumber,
                              };

                              handleSubAnswereChange({
                                ...subItem,
                                answere: ans.answere,
                                question: getState()._question,
                                subAnswere: _subAnswere,
                              });
                            }}
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              checked={
                                getState().isSubAnswereSelected === subItem.id
                              }
                              control={<Radio />}
                              label={
                                <Typography
                                  fontSize={"16px"}
                                  fontWeight={"regular"}
                                  fontFamily={"Poppins"}
                                >
                                  {subItem.answere}
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        );
                      })}
                    </>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.buttonContainer}>
        {getState().qNo !== 1 ? (
          <FormButton
            btnText={"Back"}
            onClick={onBackPress}
            btnStyle={{
              backgroundColor: "#FFFFFF",
              color: "black",
              padding: "10px 60px",
              elevation: 0,
              border: "1.5px solid #000000",
              marginTop: "50px",
            }}
          />
        ) : (
          <div />
        )}

        {isFinished ? (
          <FormButton
            disabled={!getState().isAnswereSelected}
            btnText={"Finish"}
            onClick={() => {
              if (getState().isAnswereSelected) {
                onFinishPress();
              }
            }}
            btnStyle={{
              backgroundColor: "#5E48F1",
              marginLeft: "30px",
              color: "#FFFFFF",
              padding: "10px 60px",
              elevation: 0,
              marginTop: "50px",
            }}
          />
        ) : (
          <FormButton
            btnText={"Next"}
            disabled={!getState().isAnswereSelected}
            onClick={() => {
              if (getState().isAnswereSelected) {
                onNextPress();
              }
            }}
            btnStyle={{
              backgroundColor: "#5E48F1",
              marginLeft: "30px",
              color: "#FFFFFF",
              padding: "10px 60px",
              elevation: 0,
              marginTop: "50px",
            }}
          />
        )}
      </div>
    </>
  );
};
