import React from "react";
import { HireForm } from "../../components/HireForm";
import {
  goToPrevQuestion,
  setQuestion,
} from "../../store/Question/QuestionsActions";
import { useDispatch } from "react-redux";
import { fourthQuestion } from "./data";

export const Question2 = ({ ...rest }) => {
  const dispatch = useDispatch();

  return (
    <HireForm
      onNextPress={() => {
        dispatch(setQuestion(fourthQuestion));
      }}
      onBackPress={() => {
        dispatch(goToPrevQuestion());
      }}
      {...rest}
    />
  );
};
