import React from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { ConnectClient } from "../../ConnectClient/ConnectClient";
import { FormSuccess } from "../../FormSuccess/FormSuccess";
import { Question1 } from "../../Question1";
import { Question10 } from "../../Question10";
import { Question2 } from "../../Question2";
import { Question4 } from "../../Question4";
import { Question5 } from "../../Question5";
import { Question6 } from "../../Question6";
import { Question7 } from "../../Question7";
import { Question8 } from "../../Question8";
import { Question9 } from "../../Question9";
import { TalentSearching } from "../../TalentSearching/TalentSearching";

export const QuestionList = () => {
  const { questions } = useSelector(
    (state: ApplicationState) => state.QuestionReducer
  );

  const getQuestionNumber = () => {
    let questioNumber = 1;
    if (
      questions &&
      questions[questions.length - 1] &&
      questions[questions.length - 1].questionNumber
    ) {
      questioNumber = questions[questions.length - 1].questionNumber;
    }
    return questioNumber;
  };

  const getStep = () => {
    switch (getQuestionNumber()) {
      case 1:
        return <Question1 />;
      case 2:
        return <Question2 />;
      case 3:
        return <Question4 />;
      case 4:
        return <Question5 />;
      case 5:
        return <Question6 />;
      case 6:
        return <Question7 />;
      case 7:
        return <Question8 />;
      case 8:
        return <Question9 />;
      case 9:
        return <Question10 />;
      case 10:
        return <TalentSearching />;
      case 11:
        return <ConnectClient />;
      case 12:
        return <FormSuccess />;

      default:
        return null;
    }
  };

  return getStep();
};
