import { QuestionActionTypes } from "../redux/actiontypes";

export const setQuestion = (payload: any) => ({
  type: QuestionActionTypes.SET_CURRENT_QUESTION,
  payload,
});
export const setCurrentQuestionAnswere = (payload: any) => ({
  type: QuestionActionTypes.SET_CURRENT_QUESTION_ANSWERE,
  payload,
});
export const setSelectorQuestionAnswere = (payload: any) => ({
  type: QuestionActionTypes.SET_SELECTOR_ANSWERE,
  payload,
});
export const goToPrevQuestion = () => ({
  type: QuestionActionTypes.SET_PREV_QUESTION_ANSWERE,
});
export const removeSelectorAnswere = (payload: { id: string }) => ({
  type: QuestionActionTypes.REMOVE_SELECTOR_ANSWERE,
  payload,
});

export const setAnswereStart = () => ({
  type: QuestionActionTypes.STORE_ANSWERE_START,
});
export const setAnswereSuccess = () => ({
  type: QuestionActionTypes.STORE_ANSWERE_SUCESS,
});
export const setAnswereFailed = () => ({
  type: QuestionActionTypes.STORE_ANSWERE_FAILED,
});
export const resetStoreAnswereState = () => ({
  type: QuestionActionTypes.RESET_ANSWERE_STORE_STATE,
});

export const setMultiSelectAnswere = (payload: any) => ({
  type: QuestionActionTypes.SET_MULTI_SELECT_ANSWERE,
  payload,
});
export const removeMultiSelectAnswere = (payload: any) => ({
  type: QuestionActionTypes.REMOVE_MULTI_SELECT_ANSWERE,
  payload,
});
