export const QuestionActionTypes = {
  SET_CURRENT_QUESTION: "SET_CURRENT_QUESTION",
  SET_CURRENT_QUESTION_ANSWERE: "SET_CURRENT_QUESTION_ANSWERE",
  SET_PREV_QUESTION_ANSWERE: "SET_PREV_QUESTION_ANSWERE",
  SET_SELECTOR_ANSWERE: "SET_SELECTOR_ANSWERE",
  REMOVE_SELECTOR_ANSWERE: "REMOVE_SELECTOR_ANSWERE",

  STORE_ANSWERE_START: "STORE_ANSWERE_START",
  STORE_ANSWERE_SUCESS: "STORE_ANSWERE_SUCESS",
  STORE_ANSWERE_FAILED: "STORE_ANSWERE_FAILED",
  RESET_ANSWERE_STORE_STATE: "RESET_ANSWERE_STORE_STATE",

  SET_MULTI_SELECT_ANSWERE: "SET_MULTI_SELECT_ANSWERE",
  REMOVE_MULTI_SELECT_ANSWERE: "REMOVE_MULTI_SELECT_ANSWERE",
};
