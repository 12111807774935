import { makeStyles, withStyles } from "@mui/styles";

export const useSelectorFormStyles = makeStyles(() => {
  return {
    questionAndDropDownContainer: {
      marginTop: "50px",
    },
    questionContainer: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: "100%",
      marginBottom: "20px",
    },
    questionNumber: {
      fontWeight: "normal",
    },
    answereChip: {
      width: "max-content",
      marginTop: "20px",
      marginRight: "10px",
    },
    poplularListTitle: {
      fontWeight: "normal",
      marginTop: "50px",
    },
    popularChipList: {
      width: "max-content",
      marginRight: "20px",
      marginTop: "10px",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: "50px",
    },
    backkBtn: {
      backgroundColor: "white",
      color: "black",
      padding: 10,
      elevation: 0,
    },
    nextButton: {
      backgroundColor: "#2ACB8B",
      marginLeft: "30px",
      padding: 10,
    },
  };
});
