import React from "react";
import { Button, Typography } from "@mui/material";

interface Props {
  btnText: string;
  onClick: () => void;
  btnStyle?: {};
  startIcon?: React.ReactNode;
  disabled?: boolean;
}

const styles = {
  backgroundColor: "rgba(0, 0, 0, 0.12)",
  color: "rgba(0, 0, 0, 0.26)",
};
export const FormButton = ({
  btnText,
  onClick,
  btnStyle,
  startIcon,
  disabled = false,
}: Props) => {
  return (
    <Button
      disabled={disabled}
      startIcon={startIcon}
      variant="contained"
      onClick={onClick}
      style={disabled ? { ...btnStyle, ...styles } : btnStyle}
    >
      <Typography fontSize={"16px"} fontWeight={"500"} fontFamily={"Poppins"}>
        {btnText}
      </Typography>
    </Button>
  );
};
