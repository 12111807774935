import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import { formSuccessData } from "../main/ConnectClient/data";
import store from "../store";
import {
  setAnswereFailed,
  setAnswereStart,
  setAnswereSuccess,
  setQuestion,
} from "../store/Question/QuestionsActions";

export const storeHireFormAnsweres = async ({
  email = "",
  company = "",
  contactName = "",
}) => {
  try {
    store.dispatch(setAnswereStart());

    const { QuestionReducer } = store.getState();
    const { formAnsweres } = QuestionReducer;
    const hireFormCollection = collection(db, "HireForm");

    await addDoc(hireFormCollection, {
      email,
      companyName: company,
      contactName,
      formAnsweres,
      createdAt: new Date(),
    });
    store.dispatch(setAnswereSuccess());
    store.dispatch(setQuestion(formSuccessData));
  } catch (error) {
    store.dispatch(setAnswereFailed());
  }
};
export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const randomIntFromInterval = (min = 1000) => {
  return Math.floor(Math.random() * (100000 - min + 1) + min);
};
