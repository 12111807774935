export const nextQuestion = {
  questionList: {
    questionNumber: 2,
    question: "What type of project are you hiring for?",
    answeres: [
      {
        id: "2a",
        answere: "New idea or project",
        answereNumber: "a",
        subQuestion: {
          question: "Do you have product specifications ready?",
          answeres: [
            {
              id: "2A-A",
              answere: "I have a rough Idea or what I want to build",
              answereNumber: "A-A",
            },
            {
              id: "2A-B",
              answere:
                "I have a clear idea of what I want to build, but do not have written specifications and/or wireframes",
              answereNumber: "A-B",
            },
            {
              id: "2A-C",
              answere: "I have clear written specifications and/or wireframes",
              answereNumber: "A-C",
            },
          ],
        },
      },
      {
        id: "2b",
        answere: "Existing project that needs more resources",
        answereNumber: "b",
      },
    ],
  },
  questionNumber: 1,
};
export const currentQuestion = {
  questionList: {
    questionNumber: 1,
    question: "How many employees do you currently have?",
    answeres: [
      { id: "1a", answere: "1-10", answereNumber: "a" },
      { id: "1b", answere: "11-50", answereNumber: "b" },
      { id: "1c", answere: "51-200", answereNumber: "c" },
      { id: "1d", answere: "201-1000", answereNumber: "d" },
      { id: "1e", answere: "More than 1000", answereNumber: "e" },
    ],
  },
  questionNumber: 0,
};
