import { makeStyles } from "@mui/styles";
import { padding } from "@mui/system";

export const useQuestionRendererStyles = makeStyles(() => {
  return {
    container: {
      textAlign: "center",
      marginBottom: 20,
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "29.9px",
    },
    innerTextColorChanger: {
      color: "#5E48F1",
    },
    trustedByText: {
      color: "#5E48F1",
      textAlign: "center",
    },
    imageGridContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    imageContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-evenly",
    },
    image: {
      height: "60px",
      width: "70px",
      paddingRight: "10px",
      objectFit: "contain",
    },
    groupImage: {
      display: "block",
      width: "100%",
      alignSelf: "center",
      margin: "50px 0",
    },
    rendererContainer: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      height: "100vh",
      backgroundColor: "white",
    },
    leftSideContainer: {
      height: "max-content",
      marginLeft: "auto",
      marginRight: "auto",
    },
    rightSideContainer: {
      padding: "0 60px",
      display: "flex",
      background: `linear-gradient(29.5deg, rgba(94, 72, 241, 0.35) -1.74%, rgba(94, 72, 241, 0.147) 24.38%, rgba(241, 153, 72, 0.1995) 44.26%, rgba(241, 153, 72, 0) 65.47%, rgba(241, 153, 72, 0) 89.17%)`,
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
    stepCounter: {
      fontWeight: 400,
      fontSize: "16px",
      color: "#8C8C8C",
      paddingTop: "50px",
      paddingLeft: "10px",
    },
  };
});
