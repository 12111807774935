export const fourthQuestion = {
  questionList: {
    questionNumber: 3,
    question: "What kind of talent are you looking for?",
    answeres: [
      {
        id: "3a",
        answere:
          "Developers - Software Developers, Data Scientists, DevOps, and QA",
        answereNumber: "a",
        inputLabel: "Number of developers needed?",
      },
      {
        id: "3b",
        answere:
          "Project Managers - Digital Project Managers, IT Project Managers, Scrum Masters, and Agile Coaches",
        answereNumber: "b",
        inputLabel: "Number of PM needed?",
      },
      {
        id: "3c",
        answere:
          "Designers - Experts in Web, Mobile, UI/UX, Branding, and Visual elements.",
        answereNumber: "c",
        inputLabel: "Number of designers needed?",
      },
    ],
  },
  questionNumber: 2,
};
