export const question10 = {
  questionList: {
    questionNumber: 9,
    question: "What is your budget for this role?",
    answeres: [
      { id: "9a", answere: "Less than 60€/hr", answereNumber: "a" },
      {
        id: "9b",
        answere: "60€ - 80€/hr",
        answereNumber: "b",
      },
      {
        id: "9c",
        answere: "81€ - 100€/hr",
        answereNumber: "c",
      },
      {
        id: "9d",
        answere: "More than 100€/hr",
        answereNumber: "d",
      },
    ],
  },
  questionNumber: 8,
};
