import * as React from "react";
import MobileStepper from "@mui/material/MobileStepper";
import { withStyles } from "@mui/styles";

interface Props {
  activeStep: number;
}

const StyledMobileStepper = withStyles({
  root: {
    maxWidth: "100%",
    minWidth: "100%",
    flexGrow: 1,
  },
  progress: {
    minWidth: "80%",
    maxWidth: "80%",
  },
})(MobileStepper);

export const ProgressIndicator = ({ activeStep = 0 }: Props) => {
  return (
    <StyledMobileStepper
      variant="progress"
      steps={10}
      position="static"
      activeStep={activeStep}
      nextButton={null}
      backButton={null}
    />
  );
};
