import React from "react";
import { useHeaderStyles } from "../../styles/header";
import Img from "../../assets/clients/logo.png";

export const Header = () => {
  const classes = useHeaderStyles();

  return (
    <div className={classes.container}>
      <img
        onClick={() => {
          window.location.href = "https://kodingtalent.com/";
        }}
        src={Img}
        alt="logo"
        className={classes.logo}
      />
    </div>
  );
};
