export const question8 = {
  questionList: {
    questionNumber: 7,
    question: "What level of time commitment will you require?",
    answeres: [
      { id: "7a", answere: "Full time (40 hrs/week)", answereNumber: "a" },
      {
        id: "7b",
        answere: "Part time (less than 40 hrs/week)",
        answereNumber: "b",
      },
      {
        id: "7c",
        answere: "Not sure yet",
        answereNumber: "c",
      },
    ],
  },
  questionNumber: 6,
};
