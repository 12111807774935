import React, { useState } from "react";
import {
  goToPrevQuestion,
  setQuestion,
} from "../../store/Question/QuestionsActions";
import { useDispatch, useSelector } from "react-redux";
import { question5 } from "./data";
import { MultiSelectForm } from "./components/MultiSelectForm";
import { ApplicationState } from "../../store";
import { question6 } from "../Question5/data";

export const Question4 = ({ onNextPress = (id: string) => {}, ...rest }) => {
  const dispatch = useDispatch();
  const { questions } = useSelector(
    (state: ApplicationState) => state.QuestionReducer
  );
  const getFormAnsweres = () => {
    let isASelected = false;
    const multiAnasweres =
      questions &&
      questions[questions.length - 1] &&
      questions[questions.length - 1].multiSelectAnswere
        ? questions[questions.length - 1].multiSelectAnswere
        : [];
    if (multiAnasweres && multiAnasweres.length >= 1) {
      console.log({ multiAnasweres });

      isASelected = multiAnasweres.some((item) => item.id === "3a");
    }
    return isASelected;
  };
  return (
    <MultiSelectForm
      onNextPress={() => {
        if (getFormAnsweres()) {
          dispatch(setQuestion(question5));
          return;
        }
        dispatch(setQuestion(question6));
      }}
      onBackPress={() => {
        dispatch(goToPrevQuestion());
      }}
      {...rest}
    />
  );
};
