import { Typography } from "@mui/material";
import React from "react";
import { useQuestionRendererStyles } from "../../../../styles/questionRenderer";
import { ImageGrid } from "./ImageGrid";

export const ClientList = () => {
  const classes = useQuestionRendererStyles();
  return (
    <div>
      <Typography
        className={classes.container}
        fontSize={"24px"}
        fontWeight={"600"}
        fontFamily={"Poppins"}
      >
        Customise your own{" "}
        <Typography
          className={classes.innerTextColorChanger}
          fontSize={"24px"}
          fontWeight={"600"}
          fontFamily={"Poppins"}
        >
          engineering team
        </Typography>{" "}
        of software engineers and start work in{" "}
        <Typography
          className={classes.innerTextColorChanger}
          fontSize={"24px"}
          fontWeight={"600"}
          fontFamily={"Poppins"}
        >
          24 hours!
        </Typography>{" "}
      </Typography>
      <img
        alt="logo"
        className={classes.groupImage}
        src="../../../../assets/group.png"
      />
      <Typography
        className={classes.trustedByText}
        fontSize={"24px"}
        fontWeight={"bold"}
        fontFamily={"Poppins"}
      >
        Trusted by
      </Typography>
      <ImageGrid
        img1={"../../../../assets/clients/bitsofstock.png"}
        img2={"../../../../assets/clients/kleta.png"}
        img3={"../../../../assets/clients/octapharma.png"}
      />
      <ImageGrid
        img1={"../../../../assets/clients/bunahabhain.png"}
        img2={"../../../../assets/clients/uicentric.png"}
        img3={"../../../../assets/clients/banzzu.png"}
      />
    </div>
  );
};
