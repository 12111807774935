import {
  Backdrop,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useConnectClientStyles } from "../../../styles/connectClient";
import { FormButton } from "../../components/Button";
import { storeHireFormAnsweres, validateEmail } from "../../helpers/common";
import { ApplicationState } from "../../store";

export const ConnectClient = () => {
  const classes = useConnectClientStyles();
  const { loading } = useSelector(
    (state: ApplicationState) => state.QuestionReducer
  );

  const [userData, setUserData] = useState({
    email: "",
    company: "",
    contactName: "",
  });
  const [inputState, setInputState] = useState({
    isEmailFocused: false,
    isCompanyFocused: false,
    isContactFocused: false,
  });
  const isError = () =>
    !userData.company ||
    !validateEmail(userData.email) ||
    !userData.contactName;

  return (
    <div className={classes.container}>
      <Typography
        className={classes.title}
        fontSize={"26px"}
        fontWeight={"600"}
        fontFamily={"Poppins"}
      >
        Success! Let's connect you with talent.
      </Typography>
      <TextField
        style={{
          fontSize: "16px",
          fontWeight: "regular",
          fontFamily: "Poppins",
        }}
        onFocus={() => setInputState({ ...inputState, isEmailFocused: true })}
        required
        placeholder={"Email"}
        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
        error={inputState.isEmailFocused && !validateEmail(userData.email)}
        helperText={
          inputState.isEmailFocused && !validateEmail(userData.email)
            ? "Invalid Email"
            : " "
        }
      />
      <TextField
        style={{
          fontSize: "16px",
          fontWeight: "regular",
          fontFamily: "Poppins",
        }}
        onFocus={() => setInputState({ ...inputState, isCompanyFocused: true })}
        placeholder={"Company name"}
        onChange={(e) => setUserData({ ...userData, company: e.target.value })}
        error={inputState.isCompanyFocused && !userData.company}
        helperText={
          inputState.isCompanyFocused && !userData.company
            ? "Company name cannot be empty"
            : " "
        }
      />
      <TextField
        style={{
          fontSize: "16px",
          fontWeight: "regular",
          fontFamily: "Poppins",
        }}
        onFocus={() => setInputState({ ...inputState, isContactFocused: true })}
        placeholder={"Contact name"}
        onChange={(e) =>
          setUserData({ ...userData, contactName: e.target.value })
        }
        error={inputState.isContactFocused && !userData.contactName}
        helperText={
          inputState.isContactFocused && !userData.contactName
            ? "Contact name cannot be empty"
            : " "
        }
      />
      <Typography
        fontSize={"13px"}
        fontWeight={"regular"}
        fontFamily={"Poppins"}
        color={" #7F7F7F"}
      >
        By submitting your information , you are agreeing to koding talent{" "}
        <a target="_blank" href="https://kodingtalent.com/terms-conditions/">
          Terms & Conditions
        </a>
        ,{" "}
        <a target="_blank" href="https://kodingtalent.com/privacy-policy/">
          Privacy Policy
        </a>{" "}
        and{" "}
        <a target="_blank" href="https://kodingtalent.com/cookies-policy/">
          Cookie Policy
        </a>
        .
      </Typography>
      <Divider className={classes.divider} />
      <FormButton
        disabled={isError()}
        btnText={"Connect Me With Talent"}
        onClick={() => {
          storeHireFormAnsweres(userData);
        }}
        btnStyle={{
          backgroundColor: "#5E48F1",
          marginLeft: "30px",
          color: "#FFFFFF",
          padding: "15px",
          alignSelf: "center",
          width: "50%",

          elevation: 0,
        }}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
