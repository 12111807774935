import { makeStyles } from "@mui/styles";

export const useHireFormStyles = makeStyles(() => {
  return {
    questionAnswereContainer: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      minHeight: "400px",
      maxHeight: "400px",
    },
    questionContainer: { marginTop: "50px", height: "100%", width: "100%" },
    questionInnerContainer: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: "100%",
      marginBottom: "20px",
    },
    questionNumberText: {
      fontWeight: "normal",
    },
    questionTextContainer: {
      width: "30px",
      height: "30px",
      borderRadius: 30,
      padding: 3,
      boxShadow: "-2px -2px 2px -1px #5e48f1 inset",
      alignItem: "center",
      justifyContent: "center",
      marginRight: "18px",
      display: "flex",
    },
    answereContainer: {
      cursor: "pointer",
      fontWeight: "lighter",
      marginLeft: "30px",
      display: "flex",
      flexDirection: "row",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "80%",
      marginBottom: "30px",
    },
    backButton: {
      backgroundColor: "white",
      color: "black",
      padding: 10,
    },
    finishButton: {
      backgroundColor: "#2ACB8B",
      marginLeft: "30px",
      padding: 10,
    },
    nextButton: {
      backgroundColor: "#2ACB8B",
      marginLeft: "30px",
      padding: 10,
    },
  };
});
