export const question7 = {
  questionList: {
    questionNumber: 6,
    question: "For how long would you need your new hire?",
    answeres: [
      { id: "6a", answere: "1 to 4 weeks", answereNumber: "a" },
      {
        id: "6b",
        answere: "1 to 2 months",
        answereNumber: "b",
      },
      {
        id: "6c",
        answere: "3 to 6 months",
        answereNumber: "c",
      },
      {
        id: "6d",
        answere: "Longer than 6 months",
        answereNumber: "d",
      },
      {
        id: "6e",
        answere: "I am not sure yet",
        answereNumber: "e",
      },
    ],
  },
  questionNumber: 5,
};
