import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { FormButton } from "../../components/Button";
import img from "../../../assets/clients/user1.png";
import img2 from "../../../assets/clients/user2.png";
import img3 from "../../../assets/clients/user3.png";

export const FormSuccess = () => {
  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        flexWrap: "wrap",
        alignItems: "center",
        margin: "0px 20px",
      }}
    >
      <Grid
        xs={12}
        lg={6}
        md={6}
        sm={12}
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "150px",
        }}
        justifyContent={{ xs: "center", lg: "space-evenly", sm: "center" }}
        textAlign={{ xs: "center", lg: "left" }}
      >
        <Typography
          fontSize={"26px"}
          fontWeight={"bold"}
          fontFamily={"Poppins"}
        >
          Thanks for filling out the form!
        </Typography>
        <Typography
          fontSize={"20px"}
          fontWeight={"regular"}
          fontFamily={"Poppins"}
          style={{ padding: "50px 0px" }}
        >
          A member of the team will contact
          <br />
          you with the talent that matches
          <br />
          with you soon...
        </Typography>
        <Box
          style={{
            width: "250px",
          }}
          alignSelf={{ xs: "center", lg: "flex-start" }}
        >
          <FormButton
            btnText="Go to Home"
            onClick={() => {
              window.location.href = "https://kodingtalent.com/";
            }}
            btnStyle={{
              backgroundColor: "#5E48F1",
              color: "#FFFFFF",
              padding: "18px 60px",
              elevation: 0,

              textTransform: "capitalize",
              marginBottom: "50px",
            }}
          />
        </Box>
      </Grid>
      <Grid
        display={{ xs: "none", lg: "block" }}
        lg={4}
        md={6}
        sm={12}
        xs={12}
        style={{ marginTop: "40px" }}
      >
        <img
          alt="logo"
          style={{
            height: "118px",
            width: "118px",
            alignSelf: "flex-end",
            marginBottom: "39px",
            marginLeft: "100px",
          }}
          src={img}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <img
            alt="logo"
            style={{ height: "99px", width: "99px" }}
            src={img3}
          />
          <img
            alt="logo"
            style={{
              height: "78px",
              width: "78px",
              marginLeft: "51px",
            }}
            src={img2}
          />
        </div>
      </Grid>
    </Grid>
  );
};
