import React from "react";

import { ProgressIndicator } from "../../components/Stepper";
import { Header } from "../../components/Header";
import { ClientList } from "./components/ClientList";
import { QuestionList } from "./components/QuestionList";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { Divider, Grid, Typography } from "@mui/material";
import { useQuestionRendererStyles } from "../../../styles/questionRenderer";

export const QuestionRenderer = () => {
  const { questions } = useSelector(
    (state: ApplicationState) => state.QuestionReducer
  );
  const classes = useQuestionRendererStyles();

  const getQuestionNumber = () => {
    let questioNumber = 1;
    if (
      questions &&
      questions[questions.length - 1] &&
      questions[questions.length - 1].questionNumber
    ) {
      questioNumber = questions[questions.length - 1].questionNumber;
    }
    return questioNumber;
  };

  return (
    <Grid className={classes.rendererContainer}>
      <Divider />
      <Grid xs={12} lg={6} md={6} sm={12} className={classes.leftSideContainer}>
        <Header />
        {getQuestionNumber() <= 9 && (
          <Typography className={classes.stepCounter}>
            Step {getQuestionNumber()} of 9
          </Typography>
        )}
        {getQuestionNumber() <= 9 && (
          <ProgressIndicator activeStep={getQuestionNumber()} />
        )}
        <QuestionList />
      </Grid>
      <Grid
        lg={4}
        md={6}
        sm={12}
        xs={12}
        className={classes.rightSideContainer}
      >
        <ClientList />
      </Grid>
    </Grid>
  );
};
